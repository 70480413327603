<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">PENCAPAIAN NILAI</p>
        </header>
        <div class="card-content table-card-content">
          <b-field
            position="is-right"
            class="filter-field"
            grouped
            group-multiline
          >
            <generic-filter-select
              class="filter3"
              apiPath="/stase/tahun-options/"
              field="tahun"
              v-model="filterMap.tahun"
            ></generic-filter-select>
            <penilaian-select
              class="filter2"
              v-model="filterMap.dinilai"
            ></penilaian-select>
            <generic-filter-select
              class="filter3"
              apiPath="/matakuliah/options/"
              field="mata kuliah"
              v-model="filterMap.mata_kuliah"
            ></generic-filter-select>
            <cari-input class="filter3" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          <div class="box-container">
            <small v-if="staseList.length > 0" class="page-info is-pulled-right"
              ><strong>{{ pagination.startRowNum }}</strong> -
              <strong>{{
                pagination.startRowNum + staseList.length - 1
              }}</strong>
              dari <strong>{{ pagination.total }}</strong></small
            >
            <div class="cleardiv"></div>
            <box
              v-for="stase in staseList"
              :key="stase.id"
              :title="stase.mata_kuliah"
              :subtitle1="`${stase.mulai} - ${stase.hingga}`"
              :subtitle2="stase.preceptor"
              :detailUrl="{
                name: 'pencapaian-nilai-detail',
                params: { id: stase.id },
              }"
            />
            <box v-if="staseList.length == 0">
              <template v-slot:content>
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </section>
              </template>
            </box>
          </div>
          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clone, has } from "lodash";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import StaseList from "../models/pencNilaiList.js";

export default {
  name: "PencapaianNilaiList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
    PenilaianSelect: () =>
      import("@/apps/stase/components/PenilaianSelect.vue"),
  },
  data() {
    this.staseListMdl = new StaseList();
    this.listMdl = this.staseListMdl; // bikin alias
    return this.staseListMdl.getObservables();
  },
  mixins: [onlineCheckMixin, paginationMixin],
  activated() {
    if (
      has(this.$route.params, "dinilai")
    ) {
      this.staseListMdl.reset();
      this.filterMap.dinilai = this.$route.params.dinilai;
      this.filterMap.tahun = null;
      this.filterMap.mata_kuliah = null;
      this.filterMap.cari = "";
      this.filterPrevMap = clone(this.filterMap);
    }
    this.fetchData();
  },
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";

.ol-style {
  margin-left: 15px;
  padding: 5px;
}

.margin-left {
  margin-left: 15px;
}
/* ::v-deep .kel-filter {
  width: 12rem;
} */
</style>
